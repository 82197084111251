.nav-row {
  height: 55%;
  text-align: center;
  margin-top: 15%;

  a {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.14em;
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 500;
  }
  a:hover {
    color: #000000;
    text-decoration: none;
  }

  ul, ol {
      font-size: 0;
      list-style-type: none;
      border-bottom: 2px solid #ffffff;
      padding-bottom: 20px;
      padding-top: 20px;
      li {
        display:inline-block;
        font-size: 14px;
        margin-left: 18px;
      }
      li:first-child {
        margin-left: 0px;
      }
  }

  ul:last-child {
    border-bottom: none;
  }
}

@media screen and (max-width: 500px) {
  .nav-row {
    ul {
      padding-left: 0;
    }
    a {
      font-size: 9px;
      line-height: 12px;
      letter-spacing: unset;
    }
  }
}