.home {
  height: 100vh;
  width: 100vw;

  .container {
    background-image: url('../static/images/water_background.jpg');
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    width: 70%;

    .search-row {
      height: 30%;
      display: flex;
      align-items: flex-end;
    }

    .mt-5 {
      margin-top: 10%;
    }

    form {
      display: flex;
      justify-content: center;

      .input-group {
        width: 346px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    .search-row {
      .input-group {
        width: 100% !important;
      }
    }
  }
}