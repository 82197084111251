/* ==========================================================================
navigation section
========================================================================== */
.custom-navbar {
  background: #3D5B94 !important;
  margin-bottom: 0;
  padding: 10px;

  .navbar-brand {
    color: #00c6d7;
    font-weight: 600;
    font-size: 24px;

    .navbar-toggle {
      background: #00c6d7;
      padding: 4px 6px;
      font-size: 16px;
      color: #fff;
    }
  }

  .nav {
    li {
      a {
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          background: transparent;
          color: #000000;
        }
      }

      &.active > a {
        color: #00c6d7;
      }
    }

    > li > a:focus {
      background-color: transparent;
      color: #ffffff;
      outline: none;
    }
  }

  .navbar-toggle {
    border: none;
    padding-top: 10px;

    .icon-bar {
      background: #ffffff;
      border-color: transparent;
    }
  }
}
@media(min-width:768px){
  .custom-navbar {
    background: #3D5B94;
    padding: 10px;
    border-bottom: 0;
    background: 0 0;

    &.top-nav-collapse {
      padding: 8px;
      background: #3D5B94;
    }
  }
}

.show-menu {
  display: block;
  overflow-y: auto;
  ul {
    text-align: left;
  }
}
.hide-menu {
  display: none;
}

.navbar {
  .container {
    width: 100%;
    display: block !important;
    background: #3D5B94 !important;
  }
}

.navbar-brand {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .title {
      color: #ffffff;
      font-size: 28px;
      font-family:'Elephant Regular';
      letter-spacing: 0.05em;
    }

    .tagline {
      //color: #3D5B94;
      color: #ffffff;
      font-size: 12px;
      font-family: 'Baskerville old face';
      letter-spacing: 0.15em;
    }
}

@media screen and (max-width: 500px){
  .custom-navbar {
    .container {
      height: unset;
    }
  }
}