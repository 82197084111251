.generic-router {
  width: 100vw;
  //overflow: auto;

  .container {
    background: #ffffff;

    display: flex;
    flex-flow: column;
    align-items: center;

    .row {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px){
  .generic-router {
    .container {
      height: unset;
    }
  }
}