@import url(https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Charlie Text';
  font-style: normal;
  font-weight: normal;
  src: local('Elephant Regular'), url(/static/media/Charlie_Text-Regular.28ac7e81.woff2) format('woff2');
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-footer {
  text-align: left;
}

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-body img{
  width: 100%;
}

.modal-body .speciality {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
}

.modal-body .email{
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10%;
  word-break: break-word;
}

.modal-body .member-name {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
}

.modal-body .row {
  margin-top: 3%;
}

.modal-body p {
  text-align: justify;
  font-size: 12px;
  padding-bottom: 2%;
}

.modal-body .member-description p {
  text-align: justify;
  font-size: 16px;
}

.modal-body h3 {
  color: #8c0000;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  padding-bottom: 3%;
}

.modal-body h4 {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.14em;
  line-height: 18px;
  padding-bottom: 2%;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}

body {
  background-color: #36669d;
  background-image: linear-gradient(0deg, #36669d 0%, #d3d3d3 74%);
  background-attachment: fixed;
  font-family: 'Libre Baskerville', serif;
}
.home{height:100vh;width:100vw}.home .container{background-image:url(/static/media/water_background.cec3d6bf.jpg);background-attachment:fixed;background-size:cover;height:100%;width:70%}.home .container .search-row{height:30%;display:-webkit-flex;display:flex;-webkit-align-items:flex-end;align-items:flex-end}.home .container .mt-5{margin-top:10%}.home .container form{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.home .container form .input-group{width:346px}@media screen and (max-width: 500px){.home .search-row .input-group{width:100% !important}}

.nav-row{height:55%;text-align:center;margin-top:15%}.nav-row a{color:#ffffff;text-transform:uppercase;font-size:12px;line-height:24px;letter-spacing:0.14em;-webkit-font-smoothing:subpixel-antialiased;font-weight:500}.nav-row a:hover{color:#000000;text-decoration:none}.nav-row ul,.nav-row ol{font-size:0;list-style-type:none;border-bottom:2px solid #ffffff;padding-bottom:20px;padding-top:20px}.nav-row ul li,.nav-row ol li{display:inline-block;font-size:14px;margin-left:18px}.nav-row ul li:first-child,.nav-row ol li:first-child{margin-left:0px}.nav-row ul:last-child{border-bottom:none}@media screen and (max-width: 500px){.nav-row ul{padding-left:0}.nav-row a{font-size:9px;line-height:12px;letter-spacing:unset}}

@font-face{font-family:'Elephant Regular';font-style:normal;font-weight:normal;src:local("Elephant Regular"),url(/static/media/Elephant-PersonalUse.d99412f0.woff) format("woff")}.header{height:15vh}.header .header-container{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:flex-end;align-items:flex-end;-webkit-flex-flow:column;flex-flow:column}.header .header-container .title{color:#3D5B94;font-size:32px;font-family:'Elephant Regular';letter-spacing:0.05em}.header .header-container .tagline{color:#3D5B94;font-size:14px;font-family:'Baskerville old face';letter-spacing:0.15em}@media screen and (max-width: 500px){.header-container .title{font-size:22px !important}.header-container .tagline{font-size:9.5px !important}}

footer{width:100%;display:inline-block;height:5vh;background-color:#000000}footer .copyright{width:50%;float:left}footer .copyright p{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;color:white;font-size:0.7em;line-height:5vh;letter-spacing:1px}@media (max-width: 600px){footer .copyright p{text-align:center;padding:0}}footer .social{width:50%;float:right}footer .social a{float:left;line-height:5vh;text-decoration:none;color:white;text-align:center;font-weight:bold}footer .social a:hover{background-color:#222}.linked{background-color:#1E83AE;font-size:1.2em;width:33.33%}.face{background-color:#3D5B94;font-size:1.2em;width:33.33%}.email{font-size:1.2em;width:33.33%}.support{background-color:#6dcd9a;font-size:0.8em;width:40%}

.custom-navbar{background:#3D5B94 !important;margin-bottom:0;padding:10px}.custom-navbar .navbar-brand{color:#00c6d7;font-weight:600;font-size:24px}.custom-navbar .navbar-brand .navbar-toggle{background:#00c6d7;padding:4px 6px;font-size:16px;color:#fff}.custom-navbar .nav li a{color:#ffffff;font-size:14px;font-weight:500}.custom-navbar .nav li a:hover{background:transparent;color:#000000}.custom-navbar .nav li.active>a{color:#00c6d7}.custom-navbar .nav>li>a:focus{background-color:transparent;color:#ffffff;outline:none}.custom-navbar .navbar-toggle{border:none;padding-top:10px}.custom-navbar .navbar-toggle .icon-bar{background:#ffffff;border-color:transparent}@media (min-width: 768px){.custom-navbar{background:#3D5B94;padding:10px;border-bottom:0;background:0 0}.custom-navbar.top-nav-collapse{padding:8px;background:#3D5B94}}.show-menu{display:block;overflow-y:auto}.show-menu ul{text-align:left}.hide-menu{display:none}.navbar .container{width:100%;display:block !important;background:#3D5B94 !important}.navbar-brand{display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.navbar-brand .title{color:#ffffff;font-size:28px;font-family:'Elephant Regular';letter-spacing:0.05em}.navbar-brand .tagline{color:#ffffff;font-size:12px;font-family:'Baskerville old face';letter-spacing:0.15em}@media screen and (max-width: 500px){.custom-navbar .container{height:unset}}

.generic-router{width:100vw}.generic-router .container{background:#ffffff;display:-webkit-flex;display:flex;-webkit-flex-flow:column;flex-flow:column;-webkit-align-items:center;align-items:center}.generic-router .container .row{width:100%}@media screen and (max-width: 500px){.generic-router .container{height:unset}}

.hello-class{background-image:url(/static/media/baner.59d8d3d2.jpg);max-height:300px;min-height:200px;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-flex-flow:column;flex-flow:column;margin-bottom:5%;margin-top:10%}.hello-class .container{background:transparent}.hello-class .container h1{font-size:32px;line-height:24px;color:aliceblue;font-weight:bold;text-transform:uppercase}@media screen and (max-width: 500px){.hello-class{margin-top:25%}}

.about_us{margin-bottom:5%;text-align:justify;font-size:18px;line-height:28px}

.contact-us{width:100%}.contact-us .row{margin-bottom:5%}.contact-us .row .col-lg-4,.contact-us .row .col-sm-6,.contact-us .row .col-md-4{padding:3%;text-align:center}.contact-us svg{color:#3D5B94}.contact-us form{background:#3D5B94;padding:5%}.contact-us form .form-group{text-align:justify;color:#ffffff}.contact-us form .form-group span{color:red}.contact-us iframe{height:50vh}

.section-team{margin-bottom:5%}.section-team p,.section-team a,.section-team h1,.section-team h2,.section-team h3,.section-team h4{margin:0;padding:0}.section-team .header-section{margin-bottom:50px}.section-team .header-section .small-title{margin-bottom:25px;font-size:16px;font-weight:500;color:#3e64ff}.section-team .header-section .title{font-weight:700;font-size:45px}.section-team .single-person{margin-top:30px;padding:30px;background-color:#f6f9ff;border-radius:5px;text-align:center}.section-team .single-person:hover{background:linear-gradient(0deg, #36669d 0%, #d3d3d3 74%);cursor:pointer}.section-team .single-person .person-image{position:relative;margin-bottom:25px;border-radius:50%;border:4px dashed transparent;transition:padding .3s}.section-team .single-person .person-image img{width:50%;border-radius:50%}.section-team .single-person .person-info .full-name{margin-bottom:10px;font-size:28px;font-weight:700}.section-team .single-person .person-info .speciality{text-transform:uppercase;font-size:14px;color:#016cec}.section-team .single-person:hover .full-name,.section-team .single-person:hover .speciality{color:#fff}.section-team .no-description:hover{cursor:unset}

.accordion{width:100%;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";max-width:991px;min-width:320px;margin:50px auto}.accordion-item{margin-top:10px;border:1px solid #ddd;border-radius:6px}.accordion-item-title{position:relative;margin:0;display:-webkit-flex;display:flex;width:100%;font-size:15px;cursor:pointer;-webkit-justify-content:space-between;justify-content:space-between;-webkit-flex-direction:row-reverse;flex-direction:row-reverse;padding:14px 20px;box-sizing:border-box;-webkit-align-items:center;align-items:center;background:#3D5B94;color:#ffffff}.accordion-item-desc{display:none;font-size:14px;line-height:18px;color:#333;border-top:1px dashed #ddd;padding:10px 20px 20px;box-sizing:border-box;text-align:justify}.accordion-item input[type="checkbox"]{position:absolute;height:0;width:0;opacity:0}.accordion-item input[type="checkbox"]:checked ~ .accordion-item-desc{display:block}.accordion-item input[type="checkbox"]:checked ~ .accordion-item-title{font-weight:bold}.accordion-item input[type="checkbox"]:checked ~ .accordion-item-title .icon:after{content:"-"}.accordion-item input[type="checkbox"] ~ .accordion-item-title .icon:after{content:"+"}.accordion-item:first-child{margin-top:0}.icon{margin-left:14px;font-weight:normal}

.publication-comp{min-height:50vh}.publication-comp .publication{padding:2%;border:3px dashed #3D5B94;margin:2%}.publication-comp .publication p{font-size:18px}.publication-comp .publication a{color:#3D5B94;font-size:18px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.publication-comp .publication svg{margin-left:1%}@media screen and (max-width: 500px){.publication-comp .publication{margin-bottom:5%}}

.coming-soon{background-color:#36669d20;margin:30px;padding:30px;font-family:'Product Sans', Arial, sans-serif;width:100%}.coming-soon div{width:400px;margin:6em auto;padding:40px;background-color:rgba(255,255,255,0.84);border-radius:15em}.coming-soon div .text1{text-align:center;color:#3D5B94;text-shadow:-1px 1px 5px #ffe7b6}.coming-soon div .uc{text-align:center;color:#282c34}@media (max-width: 600px){.coming-soon{background-color:#CFD8DC}.coming-soon div{width:auto;margin:0 auto;border-radius:0;padding:1em}}@-webkit-keyframes anim1{0%{bottom:0px}25%{bottom:35px}50%{bottom:15px}75%{bottom:35px}100%{bottom:0px}}@keyframes anim1{0%{bottom:0px}25%{bottom:35px}50%{bottom:15px}75%{bottom:35px}100%{bottom:0px}}

.clientele ul{font-size:1.5em;list-style:none;margin:2em;padding:0}.clientele ul li{margin:0;margin-bottom:1em;padding-left:1.5em;position:relative}.clientele ul li:after{content:'';height:.4em;width:.4em;background:#3D5B94;display:block;position:absolute;-webkit-transform:rotate(45deg);transform:rotate(45deg);top:.5em;left:0}

.other-services{min-height:30vh}

.not-found h1{text-align:center;font-family:"Pathway Gothic One";font-size:8em;letter-spacing:2px;margin-top:10vh}.not-found h2{text-align:center;font-family:"Pathway Gothic One";font-size:1.5em;letter-spacing:2px;color:#666}.not-found h3{text-align:center;font-family:"Pathway Gothic One";font-size:1.3em;letter-spacing:2px;color:#999}.not-found h4{text-align:center;font-family:"Pathway Gothic One";padding-top:10px;font-size:1.2em;letter-spacing:2px}.not-found button{font-family:"Pathway Gothic One";letter-spacing:2px;font-size:1.1em;text-transform:uppercase;display:block;margin:20px auto;background-color:#000;color:#fff;border:none;outline:none;padding:5px 35px}.not-found button:hover{background-color:#222}@media (min-width: 768px){.not-found h2{font-size:2em}}

