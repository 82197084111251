.section-team {
  margin-bottom: 5%;

  p, a, h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
  }

  .header-section {
    margin-bottom: 50px;

    .small-title {
      margin-bottom: 25px;
      font-size: 16px;
      font-weight: 500;
      color: #3e64ff;
    }

    .title {
      font-weight: 700;
      font-size: 45px;
    }
  }

  .single-person {
    margin-top: 30px;
    padding: 30px;
    background-color: #f6f9ff;
    border-radius: 5px;
    text-align: center;

    &:hover {
      background: linear-gradient(0deg, #36669d 0%, #d3d3d3 74%);
      cursor: pointer;
    }

    .person-image {
      position: relative;
      margin-bottom: 25px;
      border-radius: 50%;
      border: 4px dashed transparent;
      transition: padding .3s;

      img {
        width: 50%;
        border-radius: 50%;
      }
    }

    .person-info {
      .full-name {
        margin-bottom: 10px;
        font-size: 28px;
        font-weight: 700;
      }

      .speciality {
        text-transform: uppercase;
        font-size: 14px;
        color: #016cec;
      }
    }

    &:hover {
      .full-name, .speciality {
        color: #fff;
      }
    }
  }

  .no-description {
    &:hover {
      cursor: unset;
    }
  }
}