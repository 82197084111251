.hello-class {
  background-image: url("../static/images/baner.jpg");
  max-height: 300px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin-bottom: 5%;
  margin-top: 10%;

  .container {
    background: transparent;

    h1 {
      font-size: 32px;
      line-height: 24px;
      color: aliceblue;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 500px) {
  .hello-class {
    margin-top: 25%;
  }
}