$dk-blue: #1E83AE;
$blue: #3D5B94;
$light-blue: #3DACDD;
$green: #6dcd9a;
$social-size: 1.2em;
$social-width: 33.33%;

footer {
  width: 100%;
  display: inline-block;
  height: 5vh;
  background-color: #000000;
  .copyright {
    width: 50%;
    float: left;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.7em;
      line-height: 5vh;
      letter-spacing: 1px;
      @media (max-width: 600px) {
        text-align: center;
        padding: 0;
      }
    }
  }
  .social {
    width: 50%;
    float: right;
    a {
      float: left;
      line-height: 5vh;
      text-decoration: none;
      color: white;
      text-align: center;
      font-weight: bold;
      &:hover {
        background-color: #222;
      }
    }
  }
}

.linked {
  background-color: $dk-blue;
  font-size: $social-size;
  width: $social-width;
}

.face {
  background-color: $blue;
  font-size: $social-size;
  width: $social-width;
}

.email {
  font-size: $social-size;
  width: $social-width;
}

.support {
  background-color: $green;
  font-size: 0.8em;
  width: 40%;
}
