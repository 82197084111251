.clientele {
  ul {
    font-size: 1.5em;
    list-style: none;
    margin: 2em;
    padding: 0;

    li {
      margin: 0;
      margin-bottom: 1em;
      padding-left: 1.5em;
      position: relative;

      &:after {
        content: '';
        height: .4em;
        width: .4em;
        background: #3D5B94;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: .5em;
        left: 0;
      }

    }

  }
}