.publication-comp {
  min-height: 50vh;
  .publication {
    padding: 2%;
    border: 3px dashed #3D5B94;
    margin: 2%;

    p {
      font-size: 18px;
    }

    a {
      color: #3D5B94;
      font-size: 18px;
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: 1%;
    }
  }
}

@media screen and (max-width: 500px) {
  .publication-comp {
    .publication {
      margin-bottom: 5%;
    }
  }
}