@font-face {
  font-family: 'Charlie Text';
  font-style: normal;
  font-weight: normal;
  src: local('Elephant Regular'), url('./static/fonts/Charlie_Text-Regular.woff2') format('woff2');
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-footer {
  text-align: left;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-body img{
  width: 100%;
}

.modal-body .speciality {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
}

.modal-body .email{
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10%;
  word-break: break-word;
}

.modal-body .member-name {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 1px;
}

.modal-body .row {
  margin-top: 3%;
}

.modal-body p {
  text-align: justify;
  font-size: 12px;
  padding-bottom: 2%;
}

.modal-body .member-description p {
  text-align: justify;
  font-size: 16px;
}

.modal-body h3 {
  color: #8c0000;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  padding-bottom: 3%;
}

.modal-body h4 {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0.14em;
  line-height: 18px;
  padding-bottom: 2%;
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}

body {
  background-color: #36669d;
  background-image: linear-gradient(0deg, #36669d 0%, #d3d3d3 74%);
  background-attachment: fixed;
  font-family: 'Libre Baskerville', serif;
}